<template>
  <div id="ProductDetailPage">
    <div v-if="productInfo" class="section">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <!-- Main Image -->
          <div class="column is-5 has-text-left">
            <b-carousel
              v-if="images.length > 0"
              :autoplay="false"
              :indicator="false"
              with-carousel-list
            >
              <b-carousel-item :key="i" v-for="(item, i) in images">
                <figure
                  class="image hoverable is-1by1"
                  @click.prevent="switchToGalleryMode(i)"
                >
                  <img class="centercrop" :src="item.image" />
                </figure>
              </b-carousel-item>
              <template slot="list" slot-scope="props">
                <b-carousel-list
                  style="box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25)"
                  v-model="props.active"
                  :data="images"
                  :items-to-show="4"
                  :has-opacity="true"
                  @switch="props.switch($event, false)"
                  as-indicator
                >
                  <template slot="item" slot-scope="list">
                    <figure class="image is-1by1">
                      <img :src="list.image" />
                    </figure>
                  </template>
                </b-carousel-list>
              </template>
            </b-carousel>
            <div v-else>
              <section class="hero has-text-centered">
                <div class="hero-body">
                  <div class="container">
                    <h2 class="title">
                      <b-icon
                        icon="emoticon-sad-outline"
                        size="is-large"
                        type="is-primary"
                      />
                    </h2>
                    <h2 class="subtitle">No Product Images</h2>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <!-- Category Story -->
          <div
            v-if="productInfo"
            class="column is-5 is-offset-1"
            style="text-align: left"
          >
            <div style="padding-bottom: 1.5rem">
              <p class="title">
                {{ productInfo.name }}
              </p>
              <!-- PRICE DISCOUNT -->
              <div v-if="isDiscount">
                <p class="subtitle is-6">
                  <b-tag type="is-danger" style="text-decoration: none">
                    {{
                      (productInfo.price / productInfo.full_price) | decimal
                    }}% OFF
                  </b-tag>
                  <span
                    style="
                      margin-left: 0.25rem;
                      text-decoration: line-through;
                      color: #b5b5b5;
                    "
                  >
                    {{ productInfo.full_price | thousandseparators }}
                    {{ $t("label.currency_unit") }}
                  </span>
                </p>
                <p class="title is-4" style="color: #f14668">
                  {{ productInfo.price | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </p>
              </div>
              <div v-else>
                <p class="title is-4">
                  {{ productInfo.price | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </p>
              </div>

              <!-- SOCIAL SHARED -->
              <div class="block" style="padding-top: 0.5rem">
                <b-icon icon="share-variant" size="is-small" /> Share
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :url="productUrl"
                  :title="sharing.title"
                  :description="sharing.description"
                  :quote="sharing.quote"
                >
                  <b-icon
                    :icon="network.icon"
                    style="margin-right: 0.75rem"
                    :style="'color: ' + network.color"
                  />
                </ShareNetwork>
                <a @click="copyUrlToClipboard()">
                  <b-icon
                    icon="content-copy"
                    style="margin-right: 0.75rem; color: gray"
                /></a>
              </div>
              <b-field label="Description">
                <p class="pre-formatted" style="padding-bottom: 1.5 rem">
                  {{ productInfo.description }}
                </p>
              </b-field>
              <div class="columns is-mobile">
                <div class="column is-6-desktop is-6-tablet is-12-mobile">
                  <b-field label="Quantity">
                    <b-numberinput
                      type="is-light"
                      :min="1"
                      :max="productInfo.instock"
                      v-model="qty"
                    />
                  </b-field>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6-desktop is-6-tablet is-12-mobile">
                  <b-button
                    v-if="productInfo.preorder"
                    type="is-primary"
                    expanded
                    @click="addToCartButtonPressed(productInfo)"
                  >
                    {{ $t("btn.pre_order") }}
                  </b-button>
                  <b-button
                    v-else
                    type="is-primary"
                    expanded
                    :disabled="productInfo.instock === 0"
                    @click="addToCartButtonPressed(productInfo)"
                  >
                    {{ $t("btn.add_to_cart") }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <!-- More Product -->
          <div
            v-if="
              productInfo.more_products && productInfo.more_products.length > 0
            "
            class="column is-11"
          >
            <p class="title is-4 has-text-left">YOU MAY ALSO LIKE</p>
            <recommend-product-list :product_list="productInfo.more_products" />
          </div>
          <!-- You May Also Like -->
          <div
            v-if="
              productInfo.relate_products &&
              productInfo.relate_products.length > 0
            "
            class="column is-11"
          >
            <p class="title is-4 has-text-left">MORE PRODUCT</p>
            <recommend-product-list
              :product_list="productInfo.relate_products"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <section class="hero is-large">
        <div class="hero-body">
          <div class="container">
            <h2 class="title">
              {{ $t("label.loading") }}
            </h2>
          </div>
        </div>
      </section>
    </div>
    <vue-easy-lightbox
      v-if="light_box_images"
      :visible="isShowGallery"
      :imgs="light_box_images"
      :index="selectedImage"
    >
      <template slot="close-btn">
        <div class="columns is-mobile">
          <div class="column is-offset-10 has-text-right">
            <b-field>
              <b-button
                size="is-medium"
                type="is-text"
                icon-left="close"
                @click="switchOffGalleryMode()"
              ></b-button>
            </b-field>
          </div>
        </div>
      </template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
// @ is an alias to /src
import Helper from "@/utils/helper";
import VueEasyLightbox from "vue-easy-lightbox";

import { mapState, mapMutations } from "vuex";
import CartApiManager from "@/api/CartApiManager";
import ProductApiManager from "@/api/ProductApiManager";
import RecommendProductList from "@/components/list/RecommendProductList.vue";

export default {
  name: "ProductDetailPage",
  components: {
    VueEasyLightbox,
    RecommendProductList,
  },
  data() {
    return {
      values: 1,
      qty: 1,
      images: [],
      selectedImage: null,
      isShowGallery: false,
      light_box_images: [],
      productUrl: null,
      productInfo: null,
      sharing: {
        title: "Sharing title",
        description: "Sharing description",
        quote: "Quote for Facebook",
      },
      networks: [
        {
          network: "facebook",
          icon: "facebook",
          color: "#1877f2",
        },
        {
          network: "twitter",
          icon: "twitter",
          color: "#1da1f2",
        },
      ],
    };
  },
  watch: {
    current_lang: function (val) {
      if (val) {
        const product_id = this.$route.params.id;
        this.getCategoryProductDetail(product_id);
      }
    },
    '$route.params': function (val) {
      if (val && val.id != this.productInfo._id) {
        // eslint-disable-next-line 
        console.log(val)
            window.scrollTo(0, 0);
    const product_id = this.$route.params.id;
    this.getCategoryProductDetail(product_id);

    this.productUrl = window.location.origin + window.location.pathname;
      }
    }
  },
  computed: {
    ...mapState(["cart", "current_lang"]),
    isDiscount: function () {
      if (
        this.productInfo.full_price &&
        this.productInfo.full_price !== this.productInfo.price
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    thousandseparators: function (value) {
      return Helper.toThousandSeparatorsFormatter(value);
    },
    decimal: function (value) {
      let discount = 1 - value;
      return Math.round((discount + Number.EPSILON) * 100);
    },
  },
  methods: {
    ...mapMutations({
      setCart: "SET_CART",
    }),
    copyUrlToClipboard: async function () {
      try {
        await navigator.clipboard.writeText(this.productUrl);
        this.$buefy.snackbar.open("Product URL was copied!");
      } catch (err) {
        this.$buefy.snackbar.open(err);
      }
    },
    switchToGalleryMode: function (index) {
      this.isShowGallery = true;
      this.selectedImage = index;
    },
    switchOffGalleryMode: function () {
      this.isShowGallery = false;
    },
    addToCartButtonPressed: function (product) {
      if (this.qty > 0 && this.qty <= this.productInfo.instock) {
        let cartItem = {
          product_id: product._id,
        };
        let matchedItem = null;
        if (this.cart.products) {
          matchedItem = this.cart.products.find(
            (item) => item.product_id === product._id
          );
        }
        if (matchedItem) {
          cartItem.quantity = matchedItem.quantity + this.qty;
        } else {
          cartItem.quantity = this.qty;
        }

        // Get Cart ID, If exist update the cart. else create new cart with item
        if (this.cart._id) {
          const cart_id = this.cart._id;
          this.requestAddtoExistingCart(cart_id, cartItem);
        } else {
          this.requestAddtoNewCart(cartItem);
        }
      }
    },
    concatVideoAndImages: function (product) {
      let result = [];
      const main_image = { title: "main_image", image: product.main_image };
      result.push(main_image);
      this.light_box_images.push(product.main_image);

      if (product.photos && product.photos.length > 0) {
        product.photos.forEach((photo, index) => {
          const content_image = {
            title: "content_image" + index,
            image: photo,
          };
          result.push(content_image);
          this.light_box_images.push(photo);
        });
      }
      // if (product.video && product.video !== "") {
      //   result.push(product.video);
      // }
      return result;
    },
    requestAddtoExistingCart: async function (cart_id, cartItem) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const response = await CartApiManager.requestUpdateItemToExistingCart(
        cart_id,
        cartItem
      );
      switch (response.code) {
        case "0":
          this.presentAddtoCartToast();
          this.setCart(response.data);
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    requestAddtoNewCart: async function (cartItem) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const response = await CartApiManager.requestCreateNewCart(cartItem);
      switch (response.code) {
        case "0":
          this.presentAddtoCartToast();
          this.setCart(response.data);
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    getCategoryProductDetail: async function (product_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const response = await ProductApiManager.requestCategoryProductDetail(
        product_id
      );
      switch (response.code) {
        case "0":
          this.productInfo = response.data;
          this.images = this.concatVideoAndImages(response.data);
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    presentAddtoCartToast() {
      this.$buefy.snackbar.open(this.productInfo.name + " was added to cart!");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const product_id = this.$route.params.id;
    this.getCategoryProductDetail(product_id);

    this.productUrl = window.location.origin + window.location.pathname;
  }
};
</script>

<style scoped>
.centercrop {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
</style>
